<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Inquiry List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:500ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :loading="loading"
        :key="items.name"
        :footer-props="{'items-per-page-options':[30, 45, 60, -1]}"
        :options.sync="pagination"
        class="elevation-1"
      >
        <template v-slot:[`item.action`]="{ item }">
        <v-btn
            color="grey darken-2"
            icon
            dark
            :to="'/inquiry/' + item._id"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.createdAt="{item}">
          {{moment(item.createdAt).format('MM/DD/YYYY')}}                   
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5"
          >Are you sure delete <b>{{ inquiry.email }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(inquiry)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { parseParams,getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "Inquiry",
  mounted() {
    this.getItems();
  },
  data() {
    return {
      loading: false,
      dialog: false,
      inquiry: {
        name: null
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Email Address", value: "email" },
        { text: "Name", value: "contact_name" },
        { text:"Contact",value:"phone"},
        { text:"For Category",value:"category_id.name", sortable: false}, 
        { text:"Inquired On",value:"createdAt"},        
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 30,
        sortBy: ["createdAt"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets()
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "customer_name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = true;
      let url = parseParams(this.pageData);
      this.$axios
        .get("admin/inquiry/list?"+url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.inquiry = item;
    },
    deleteItem(item) {
      const index = this.items?.results.indexOf(item);
      let _self = this;
      _self.loading = true;
      this.$axios
        .delete(`/admin/inquiry/delete/${item._id}`)
        .then((res) => {
          if (res?.data.status) {
            _self.dialog = false;
            _self.setAlert({
              show: true,
              variant: "success",
              message: res.data.message,
              dismissCountDown: 5000,
            });
            _self.items.results.splice(index, 1);
          }
          _self.loading = false;
          _self.dialog = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    }
  },
};
</script>